import React  from 'react';

const ProjectSection = (props) => {
    const {
        goal,
        list,
        solution,
        subtitle,
        title,
        type,
    } = props;

    return (
        <div className="project-section">
            <div className="container flex-col">
                <h2 className="project-type">{type}</h2>
                <h2 className="project-title project-text">{title}</h2>
                <h2 className="project-subtitle project-text">{subtitle}</h2>
                <div className="grid-row">
                    <div className="project-column">
                        <h3>Goal</h3>
                        <p>{goal}</p>
                    </div>
                    <div className="project-column">
                        <h3>Solution</h3>
                        <p>{solution}</p>
                    </div>
                    <div className="project-column">
                        <h3>Technologies</h3>
                        <ul className="project-tech-list">
                            {list.map((listItem, index) =>
                                <li key={index}>{listItem}</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectSection;