import React from "react"
import {
    ThumbnailConclusion,
    ThumbnailsContainer,
    ThumbnailRow,
} from './Thumbnail.js';
import ProjectSection from './ProjectSection';
import Contact from './Contact';

function DepoNotesProject() {
    return (
        <div className="main-content">
            <ProjectSection
                type="SaaS"
                title="Depo-Notes"
                subtitle="Proofread and create accurate transcriptions for legal depositions using AI"
                goal="I worked with the Co-Founder/Senior Software Engineer to build out features that met business needs. I was asked to build a payment form using stripe which in addition to processing payments can save cards for future use."
                solution="I was responsible for the front-end as well as writing Node.js Lambda Functions for use within our serverless architecture. I was thoughtful of typography and UX to make it easier for the user to digest information with clear sections."
                list={["React", "Material UI", "SCSS", "Bootstrap", "Node.js", "AWS Lambda"]}
            />
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    id="pt-12"
                    title="Stripe Checkout"
                    description='The radio button design enables the user to select a saved card (disabling the new card section) or select "Use a new card" and enter new card details. When using a new card the user can decide whether to use the card for a one time purchase or save for future use.'
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1721752700/Portfolio_Images/Screen_Shot_2020-11-24_at_9.28.22_PM_dirguw_pyu3at.png"
                    alt="Stripe Checkout Form"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Error handling"
                    description="I used Material UI's Alert Component to display the error message if the attempt resulted in an error along with a prompt to try again."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1721752588/Portfolio_Images/Screen_Shot_2020-11-24_at_9.31.04_PM_aagxqo_c_crop_w_2220_h_1628_x_0_y_0_rlhiic.png"
                    alt="Insufficent funds view"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Typography and UI design"
                    description="I used appropriately sized text, a container to create margins for spacing, and Material UI's Alert component with links to open modals for more information."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1606003173/Portfolio_Images/Screen_Shot_2020-11-21_at_5.59.05_PM_nlt2ka.png"
                    alt="Transcription Information Section"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="mbl-p0">
                <ThumbnailConclusion
                    summaryText="With this project, I got the opportunity to work with serverless architecture and dive deeper into the Stripe documentation to build a custom payment form."
                />
            </ThumbnailsContainer>
            <Contact/>
        </div>
    );
}

export default DepoNotesProject;
