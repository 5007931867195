import React from "react"
import {
    ThumbnailConclusion,
    ThumbnailsContainer,
    ThumbnailRow,
} from './Thumbnail.js';
import ProjectSection from './ProjectSection';
import Contact from './Contact';

function LinkedInProject() {
  	return (
        <div className="main-content linkedin-clone-content">
            <ProjectSection
                type="Social Media"
                title="LinkedIn Clone"
                subtitle="LinkedIn Page Feed"
                goal="Build the LinkedIn Home Page Feed including viewing posts, comments and interactions as well as creating new comments. The primary goal was to demo my ability to rapidly proto-type and build feature rich applications."
                solution="For this project I chose to use Next.js, Prisma ORM and a SQLLite DB to be able to rapidly build out a LinkedInClone that could easily be set up on another machine with seed data. Using Next.js, I was able to quickly build client-side and server-side logic. Prisma allowed me to rapidly build out queries as well as seed the DB. SQLLite was fast to set up and enabled me to get a self contained app with persistent data."
                list={[ "TypeScript", "Next.js", "React", "Sass/SCSS", "Prisma", "SQLLite", "Node.js"]}
            />
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    id="pt-12"
                    title="View Post Reactions & Comments"
                    description="Can view individual post, comments, and reactions on the page feed for the signed in account."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1712336438/Portfolio_Images/LinkedIn%20Clone/Screenshot_2024-04-05_at_12.00.15_PM_lgxgxd.png"
                    alt="LinkedIn Post with Comments"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Add Comments"
                    description="Supports commenting on posts as well as replying to existing comments. The TextArea component supports a menu for emoji selection. Replying to comments was resticted to 2 levels deep with a client side validation that checked comment depth."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1712334978/Portfolio_Images/LinkedIn%20Clone/Screenshot_2024-03-20_at_1.33.43_PM_xcqhkr.png"
                    alt="Post new comment field with emoji menu"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="View Reaction Insights Modal"
                    description="View Who Reacted to a Post or Comment."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1712353821/Portfolio_Images/LinkedIn%20Clone/Screenshot_2024-03-20_at_1.34.27_PM_kjbbz4.png"
                    alt="View Reaction Insights Modal"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="mbl-p0">
                <ThumbnailConclusion
                    githubLink="https://github.com/Sergio16T/LinkedInClone"
                    summaryText="With this project, some experiences that stood out to me were designing a schema to support nested comments, implementing recursive comment retrieval and displaying this relationship in the UI. "
                />
            </ThumbnailsContainer>
            <Contact/>
        </div>
  	);
}

export default LinkedInProject;
