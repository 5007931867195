import React from "react"
import {
    ThumbnailConclusion,
    ThumbnailsContainer,
    ThumbnailRow,
} from './Thumbnail.js';
import ProjectSection from './ProjectSection';
import Contact from './Contact';

function VicommProject() {
  	return (
        <div className="main-content">
            <ProjectSection
                type="SaaS/Social Media"
                title="Vicomm"
                subtitle="Build your following, create revenue and follow other stores."
                goal="Build a software as a service product which provides a platform for users to connect with others, host content, and create revenue with their own online store."
                solution="With this project, since the data is structured with relationships between accounts, posts, products, and orders I used SQL and created a MYSQL database. I also decided to build a GraphQL API with node.js to grab exactly what I need for the client side of the application and nothing more. For the client, I used Next.js (a React Framework) with Apollo Client to manage both local and remote data with my GraphQL API. "
                list={["MYSQL", "Node.js", "Express", "Apollo Server", "GraphQL", "AWS", "Mocha", "Chai.js", "Sinon.js", "Next.js", "React", "Apollo Client", "Styled Components", "Jest", "React-Testing-Library"]}
            />
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    id="pt-12"
                    title="Google Oauth 2.0"
                    description="The user can login/signup with their google account to reduce friction when signing up or logging into their account."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1600062153/Full%20Stack%20App/Screen_Shot_2020-09-14_at_12.41.54_AM_rgy3fy.png"
                    alt="Login"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Image Gallery"
                    description="Enable multi-select or single select with photos depending on whether or not a 'multiSelect' prop is passed into this reusable image gallery modal component. Can delete multiple photos, and is passed a 'useMLTMD' function prop to be used with the 'use' button in order to allow for different use cases."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1600062071/Full%20Stack%20App/Screen_Shot_2020-09-14_at_12.29.49_AM_ja37dh.png"
                    alt="Image Gallery Modal"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Loading state with spinner overlay"
                    description="Since the image gallery modal was scrollable, I needed to create a spinner that was positioned in the center over the modal regardless of where I was in the y-axis. By creating a spinner component that was at the same level as the modal in the DOM hierarchy, which behaved the same way as the modal, I was able to ensure it was always positioned over the modal regardless of the scroll position."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1600064669/Full%20Stack%20App/Screen_Shot_2020-09-14_at_1.23.57_AM_d6ldij.png"
                    alt="Loading Spinner"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Product Form"
                    description="Built a custom form to enable the user to save new products with images and item details."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1621008951/Portfolio_Images/Screen_Shot_2021-05-14_at_11.14.30_AM_cyxj6e.png"
                    alt="Product Form"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Product Form Gallery"
                    description="I built a gallery to display selected images with horizontal scroll and the ability to crop images, change the image order, or remove images."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1621009404/Portfolio_Images/Screen_Shot_2021-05-14_at_11.21.48_AM_pmof7m.png"
                    alt="Product Form Gallery"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Product Table"
                    description="A paginated tabular view of product items from which the user can choose to view and update individual product details. The product table is kept in sync by ensuring cache is updated each time a product is created or updated."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1621042271/Portfolio_Images/Screen_Shot_2021-05-14_at_8.30.37_PM_k4wkwm.png"
                    alt="Product Table"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="mbl-p0">
                <ThumbnailConclusion
                    githubLink="https://github.com/Sergio16T/Vicomm"
                    summaryText="With this project, some experiences that stood out to me were creating my own database with MYSQL, working with a GraphQL API,
					and working with Google Oauth 2.0."
                />
            </ThumbnailsContainer>
            <Contact/>
        </div>
  	);
}

export default VicommProject;
