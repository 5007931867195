import React from "react"

function About({ contentRef }) {
    return (
        <div className="main-content" ref={contentRef}>
            <div className="about-me-row">
                <div className="about-info">
                    <div id="toolsandtech">
                        <div id="background-sphere"></div>
                        <h2 id="toolsandtech-title">Tools and Technologies</h2>
                        <div>
                            <p id="tech-list">JavaScript, TypeScript, React, Next.js, Vue.js, Ember.js, Node.js, Python, GoLang, Java, Protocol Buffers, GraphQL, SQL (MYSQL, MSSQL, PostgreSQL), AWS, HTML5, CSS3, Sass/SCSS, Jest, React Testing Library, Mocha, Chai.js, Sinon.js, Cypress, Pytest, Material UI, Bootstrap, Git</p>
                        </div>
                    </div>
                    <div id="about-content">
                        <p id="about-message">As a Software Engineer, I love working throughout the entire stack to build <span id="vibrant">interactive
						and vibrant</span> web applications that focus on <span id="ux-design">user experience and design</span>.
						With my experience working with both Agile and Waterfall methodologies, I've cultivated and refined my
						ability to design, estimate and develop software solutions that match business needs and requirements.
						I am highly motivated to build <span id="solve-problems">quality software</span> and enjoy learning new technologies and applying them in product development.
						Take a look at my <a id="github-link"href="https://github.com/Sergio16T" target="_blank" rel="noopener noreferrer">Github</a>.</p>
                    </div>
                </div>
            </div>
            <div className="blog-section">
                <h2 id="blog-header">Blog</h2>
                <div className="blog-card-container">
                    <BlogCard
                        title="A guide to MYSQL with Node.js Part 1: Enabling Async and Await, SQL Statement Syntax..."
                        link="https://medium.com/fullstackwebdev/a-guide-to-mysql-with-node-js-fc4f6abce33b"
                        thumbnail="https://res.cloudinary.com/dddnhychw/image/upload/v1598624515/Untitled_design_1_uq4gk2.png"
                        publishDate="August 23, 2020"
                    />
                    <BlogCard
                        title="Advanced Component Patterns in React Part 2: Higher Order Components, Custom React Hooks..."
                        link="https://medium.com/fullstackwebdev/advanced-component-patterns-in-react-part-2-higher-order-components-custom-react-hooks-and-224c95d85244"
                        thumbnail="https://res.cloudinary.com/dddnhychw/image/upload/v1598624498/React_Hooks_Image_fcx5xi.jpg"
                        publishDate="July 3, 2020"

                    />
                </div>
            </div>
        </div>
    );
}

const BlogCard = ({ link, publishDate, thumbnail, title }) => {
    return (
        <div className="blog-card" style={{ backgroundImage: `url(${thumbnail})` }}>
            <div className="overlay bg-blue"/>
            <a href={link} target="_blank" rel="noopener noreferrer"> </a>
            <div className="read-more-container">
                <p>Read More</p>
            </div>
            <div className="blog-card-info">
                <div className="blog-title">
                    <h3>{title}</h3>
                </div>
                <div className="divider"></div>
                <div className="blog-publish-date">
                    <p className="publish-date">Published: {publishDate}</p>
                </div>
            </div>
        </div>
    );
}

export default About;