import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PDF from '../resume.pdf';


const ExpandingNav = () => {
    const [state, setState] = useState(false);
    const { isOpen, transition } = state;
    const { pathname } = useLocation();
    const menuOverlay = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        menuOverlay.current.classList.remove('open');
        document.querySelector('body').style.overflow = "";
        setState({
            isOpen: false,
            transition: false,
        });
    }, [pathname]);

    const toggleMenu =() => {
        if (isOpen) {
            setState({
                isOpen: false,
                transition: true,
            });
            menuOverlay.current.classList.remove('open');
            document.querySelector('body').style.overflow = "";
        } else {
            setState({
                isOpen: true,
                transition: true,
            });
            document.querySelector('body').style.overflow = "hidden";
            setTimeout(() => menuOverlay.current.classList.add('open'), 500);
        }

    }

    const handleRoute = (path) => {
        if (path === pathname) {
            window.scrollTo(0, 0);
            menuOverlay.current.classList.remove('open');
            document.querySelector('body').style.overflow = "";
            setState({
                isOpen: false,
                transition: false,
            });
        }
    }

    return (
        <div>
            <ExpandingCircle
                isOpen={isOpen}
                transition={transition}
            />
            <MenuOverlay
                handleRoute={handleRoute}
                isOpen={isOpen}
                ref={menuOverlay}
            />
            <Menu
                isOpen={isOpen}
                toggleMenu={toggleMenu}
            />
        </div>
    );
}

const Menu = ({ isOpen, toggleMenu }) => {
    return (
        <div className="menu-link" onClick={toggleMenu}>
            <div className={`${isOpen ? "open" : ""} menu-icon`}>
                <span className="menu-line menu-line-1"></span>
                <span className="menu-line menu-line-2"></span>
                <span className="menu-line menu-line-3"></span>
            </div>
        </div>
    );
}

const ExpandingCircle = ({ isOpen, transition }) => {
    return (
        <div className={`menu ${isOpen ? "open" : ""}`}>
            {/* <span className="menu-circle"></span> */}
            <svg className={`hamburger-toggle__circle ${transition ? "" : "no-transition"}`} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16"/>
            </svg>
        </div>
    );
}

const MenuOverlay = forwardRef(({ handleRoute }, ref) => (
    <div className="menu-overlay" ref={ref}>
        <ul>
            <li>
                <Link onClick={() => handleRoute("/")} to="/">Home</Link>
            </li>
            <li>
                <Link onClick={() => handleRoute("/projects")} to="/projects">Projects</Link>
            </li>
            <li>
                <a href={PDF} target="_blank" rel="noopener noreferrer">Resume</a>
            </li>
        </ul>
    </div>
));

export default ExpandingNav;
