import React from 'react';
import Stars from './Stars';

const Masthead = (props) => {
    const {
        render,
        subtitle,
        title,
    } = props;

    return (
        <div className="masthead">
            <Stars/>
            <div className="head-text">
                <h1 className="mh-title">{title}</h1>
                <h2 className="mh-subtitle">{subtitle}</h2>
                {render()}
            </div>
        </div>
    );
}

export default Masthead;