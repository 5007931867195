import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ActionButton = (props) => {
    const [arrowActive, setArrowActive] = useState(false);
    const {
        scrollIntoView,
        text,
        to,
    } = props;

    const rotateArrow =() => {
        setTimeout(() => {
            scrollIntoView(0, "smooth");
        }, 500);
        setArrowActive(true);
        setTimeout(() => {
            setArrowActive(false);
        }, 1500);
    }

    return (
        <div>
            <Link to={to} onClick={rotateArrow}>
                <button className={`${arrowActive ? 'action-active' : ''} action-btn`} type="button">
                    {text} <i id="arrow" className={`${arrowActive ? 'btn-active': ''} fas fa-arrow-right`}></i>
                </button>
            </Link>
        </div>
    );
};

export default ActionButton;