import React from "react"
import {
    ThumbnailColumns,
    ThumbnailConclusion,
    ThumbnailsContainer,
    ThumbnailRow,
} from './Thumbnail.js';
import ProjectSection from './ProjectSection';
import Contact from './Contact';

function FuerzaGeneration() {
    return (
        <div className="main-content">
            <ProjectSection
                type="Website"
                title="Fuerza Generation"
                subtitle="Nonprofit organization for economic empowerment"
                goal="As the technical director for Fuerza Generation, a 501(c)(3) nonprofit organization, I was responsible for the website and supporting the technical needs of the organization."
                solution="I built the website using React, Sass/SCSS, Material UI, Apollo GraphQL, Node.js and Express. I opted for building a back-end with GraphQL with queries and mutations since the nonprofit will have needs for a more robust web application in the future with a potential SaaS so I set up the architecture for those needs."
                list={["React", "Material UI", "SCSS", "Styled Components", "Node.js", "Express", "GraphQL", "Apollo", "AWS"]}
            />
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    id="pt-12"
                    title="Intuitive navigation and inviting color scheme"
                    description='I created a resusable title section design with different color variants. I also used a minimalistic design for the content of pages with fun images to accompany information and plenty of white space.'
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1621473527/Portfolio_Images/Screen_Shot_2021-05-19_at_8.16.57_PM_ny2dhp.png"
                    alt="Hero Section Image"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Form Validation"
                    description="Displayed error messages to prompt the user to fill out required fields."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1621473527/Portfolio_Images/Screen_Shot_2021-05-19_at_8.17.41_PM_rixznz.png"
                    alt="Validation"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="mbl-p0" classList="mobile-preview">
                <ThumbnailColumns
                    title = "Mobile Menu"
                    description ="Built out custom mobile menu to accomodate the nested sub menus in the navigation bar."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1609483690/Portfolio_Images/Screen_Shot_2021-01-01_at_12.47.42_AM_zwenli.png"
                    alt="Mobile Menu"
                />
                <div id="background-sphere-1"></div>

            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Snack Bar Alerts"
                    description="After the user submits the form, I cleared the form and used Material UI's snack bar alert to display either a success message or an error message."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1621473527/Portfolio_Images/Screen_Shot_2021-05-19_at_8.18.20_PM_uaalnb.png"
                    alt="Snack Bar Alerts"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="mbl-p0">
                <ThumbnailConclusion
                    summaryText="I enjoyed building out the user interface and creating animations and visual effects while staying minimalistic."
                />
            </ThumbnailsContainer>
            <Contact/>
        </div>
    );
}

export default FuerzaGeneration;
