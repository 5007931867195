import React from "react";
import ProfileBadge  from "./Profilebadge";

function Contact() {
    return (
        <div className ="contact-container" id="content-container">
            <div className="static-star-1"></div>
            <div className="static-star-2"></div>
            <div className="static-star-3"></div>
            <div className="static-star-4"></div>
            <div className="static-star-5"></div>
            <div className="static-star-6"></div>
            <div className="static-star-7"></div>
            <h2 id="contact-header">Get in touch</h2>
            <ProfileBadge/>
        </div>
    );
}

export default Contact;
