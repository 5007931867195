import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Projects from './components/Projects';
import HomePage from './components/Homepage';
import Vicomm from './components/Vicomm';
import AustinsList from './components/AustinsList';
import DepoNotes from './components/DepoNotes';
import CoinPro from './components/CoinPro';
import FuerzaGeneration from './components/FuerzaGeneration';
import smoothscroll from 'smoothscroll-polyfill';
import ExpandingNav from './components/ExpandingNav';
import LinkedInClone from './components/LinkedInClone';
import NotFound from './components/NotFound';
import './styles/index.scss';

class App extends React.Component {

    componentDidMount() {
        smoothscroll.polyfill();
    }

    scrollIntoView = (time, behavior) => {
        setTimeout(() => {
            this.contentContainer.scrollIntoView({ behavior: behavior });
        }, time);
    }

    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    <ExpandingNav/>
                    <Switch>
                        <Route exact path="/" render={(props) => <HomePage {...props} scrollIntoView={this.scrollIntoView} contentRef={(el)=> this.contentContainer = el }/>}/>
                        <Route exact path="/projects" render={(props) => <Projects {...props}  scrollIntoView={this.scrollIntoView} contentRef={(el)=> this.contentContainer = el }/>}/>
                        <Route exact path="/vicomm" component={Vicomm}/>
                        <Route exact path="/austinslist" component={AustinsList}/>
                        <Route exact path="/depo-notes" component={DepoNotes}/>
                        <Route exact path="/coin-pro" component={CoinPro}/>
                        <Route exact path="/fuerza" component={FuerzaGeneration}/>
                        <Route exact path="/linkedin-clone" component={LinkedInClone}/>
                        <Route path="*" component={NotFound}/>
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;

