import React from "react"
import {
    ThumbnailConclusion,
    ThumbnailsContainer,
    ThumbnailRow,
} from './Thumbnail.js';
import ProjectSection from './ProjectSection';
import Contact from './Contact';

function CoinProProject() {
    return (
        <div className="main-content coin-pro-content">
            <ProjectSection
                type="FinTech"
                title="Coin Pro"
                subtitle="Crypto Portfolio"
                goal="Create a web app with the CoinBase API so that I can view my Crypto Portfolio in detail with more information about my total holdings & purchase history including gains & losses, current value & price, cost-basis, cost-basis/share and purchase dates."
                solution="Developed a web application with the CoinBase API to get Portfolio Insights in Real Time. I developed the UI with Next.js (a React Framework) and Apollo Client. I designed a GraphQL API and built the back-end with Node.js, Express, and Apollo Server."
                list={["React", "Next.js", "SCSS", "Node.js", "Express", "GraphQL", "MySQL", "Jest", "React-Testing-Library", "Mocha", "Chai.js", "Sinon.js"]}
            />
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    id="pt-12"
                    title="Portfolio Insights"
                    description=""
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1663474036/Portfolio_Images/Screen_Shot_2022-05-08_at_11.28.14_PM_l68jhr.png"
                    alt="Crypto Portfolio"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="mbl-p0">
                <ThumbnailConclusion
                    summaryText="With this project, I worked with the CoinBase API to develop a Portfolio Monitoring software solution."
                />
            </ThumbnailsContainer>
            <Contact/>
        </div>
    );
}

export default CoinProProject;
