import React from "react";
import Contact from './Contact';
import Masthead from "./Masthead";
import ActionButton from './ActionButton';

function Projects({ contentRef, scrollIntoView }) {
    return (
        <div>
            <Masthead
                render={() => <ActionButton scrollIntoView={scrollIntoView} text="Explore"  to="/projects"/>}
                subtitle="A selection of 7 projects"
                title="Work"
            />
            <PortfolioOfWork contentRef={contentRef}/>
            <Contact/>
        </div>
    );
}

const PortfolioOfWork = (props) => {
    return (
        <div className="main-content" ref={props.contentRef}>
            <div className="project-introduction">
                <div className="svg-row">
                    <div className="svg-container">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" style={{ stroke: "#0a0a36", fill: "none" }}>
                            <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M1,63L1,2L62,63Z" style={{ strokeDasharray: "209, 211", strokeDashoffset: "0",  stroke: "#0a0a36", fill: "none" }}/>
                            <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M14,54L14,30L38,54Z" style={{ strokeDasharray: "82, 84", strokeDashoffset: "0",  stroke: "#0a0a36", fill: "none" }} />
                            <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M8,22L2,22" style={{ strokeDasharray: "6, 8", strokeDashoffset: "0",  stroke: "#0a0a36", fill: "none" }}/>
                            <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M6,30L2,30" style={{ strokeDasharray: "4, 6", strokeDashoffset: "0",  stroke: "#0a0a36", fill: "none" }}/>
                            <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M8,38L2,38" style={{ strokeDasharray: "6, 8", strokeDashoffset: "0",  stroke: "#0a0a36", fill: "none" }}/>
                            <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M6,46L2,46" style={{ strokeDasharray: "4, 6", strokeDashoffset: "0",  stroke: "#0a0a36", fill: "none" }}/>
                            <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M8,54L2,54" style={{ strokeDasharray: "6, 8", strokeDashoffset: "0",  stroke: "#0a0a36", fill: "none" }}/>
                            <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M25,1L19,7L55,43L63,45L61,37Z" style={{ strokeDasharray: "127, 129", strokeDashoffset: "0",  stroke: "#0a0a36", fill: "none" }}/>
                            <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M25,13L31,7" style={{ strokeDasharray: "9, 11", strokeDashoffset: "0",  stroke: "#0a0a36", fill: "none" }}/>
                        </svg>
                        <span>Attention to Detail</span>
                    </div>
                    <div className="svg-container">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64">
                            <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M1,21c0,20,31,38,31,38s31-18,31-38  c0-8.285-6-16-15-16c-8.285,0-16,5.715-16,14c0-8.285-7.715-14-16-14C7,5,1,12.715,1,21z" style={{ strokeDasharray: "198, 200", strokeDashoffset: "0", stroke: "#0a0a36" }}/>
                        </svg>
                        <span>Built with Love</span>
                    </div>
                </div>
                <div className="project-about-intro">
                    <p> I am passionate about building software <span className="blue-txt">thoughtfully</span>.
						I specialize in building web applications on product teams with my expertise being in front-end development. I prioritize readability and clean code while also striving for
                        code that is easily extendable and flexible for reuse when appropriate. Below you can explore some of my personal projects as well as contract work experience.
						To learn more about my experience as a <span className="blue-txt">full-time software engineer</span> you can explore my resume which is accessible in the menu.
                    </p>
                </div>
            </div>
            <div className="portfolio-work">
                <div className="work-section">
                    <div className="section-grid">
                        <div className="project-card" id="LinkedInClone">
                            <a href="/linkedin-clone"> </a>
                            <div className="overlay dark-blue"></div>
                            <div className="work-info">
                                <span className="work-category h-gray">Social Media</span>
                                <h3 className="work-title h-linkedin-blue">LinkedIn Clone</h3>
                            </div>
                            <div className="project-details-section dark-blue-1"/>
                        </div>
                        <div className="project-card" id="CoinPro">
                            <a href="/coin-pro"> </a>
                            <div className="overlay dark-blue"></div>
                            <div className="work-info">
                                <span className="work-category h-gray">FinTech</span>
                                <h3 className="work-title h-sky-blue">Coin Pro</h3>
                            </div>
                            <div className="project-details-section c-blue"/>
                        </div>
                        <div className="project-card" id="FunkyGo">
                            <a href="https://pkg.go.dev/github.com/Sergio16T/funky_go" target="_blank" rel="noopener noreferrer"> </a>
                            <div className="overlay dark-blue"></div>
                            <div className="work-info">
                                <span className="work-category h-gray">Open Source Package</span>
                                <h3 className="work-title h-go-blue">Funky Go</h3>
                            </div>
                            <div className="project-details-section dark-blue-1"/>
                        </div>
                        <div className="project-card" id="DepoNotes">
                            <a href="/depo-notes"> </a>
                            <div className="overlay dark-blue"></div>
                            <div className="work-info">
                                <span className="work-category h-gray">SaaS</span>
                                <h3 className="work-title">Depo-Notes</h3>
                            </div>
                            <div className="project-details-section dark-blue-1"/>
                        </div>
                        <div className="project-card" id="Fuerza">
                            <a href="/fuerza"> </a>
                            <div className="overlay dark-blue"></div>
                            <div className="work-info">
                                <span className="work-category h-gray">Website</span>
                                <h3 className="work-title">Fuerza Generation</h3>
                            </div>
                            <div className="project-details-section purple"/>
                        </div>
                        <div className="project-card" id="Vicomm">
                            <a href="/vicomm"> </a>
                            <div className="overlay sky-blue"></div>
                            <div className="work-info">
                                <span className="work-category h-blue">SaaS</span>
                                <h3 className="work-title">Vicomm</h3>
                            </div>
                            <div className="project-details-section sky-blue-1"/>
                        </div>
                        <div className="project-card" id="Austins-List">
                            <a href="/austinslist"> </a>
                            <div className="overlay navy"></div>
                            <div className="work-info">
                                <span className="work-category h-gray">E-Commerce</span>
                                <h3 className="work-title h-yellow">Austin's List</h3>
                            </div>
                            <div className="project-details-section navy-1"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// const ProjectCard = ({
//     id,
//     href,
//     category,
//     title,
//     classList,
// }) => {
//     return (
//         <div className="project-card" id={id}>
//             <a href={href}> </a>
//             <div className={`overlay ${classList.overlay}`}></div>
//             <div className="work-info">
//                 <span className={`work-category ${classList.category}`}>{category}</span>
//                 <h3 className={`work-title ${classList.title}`}>{title}</h3>
//             </div>
//             <div className={`project-details-section ${classList.details}`}/>
//         </div>
//     )
// }

export default Projects;
