import React from 'react';

const Stars = () => {
    return (
        <React.Fragment>
            <div className="star  s-3"></div>
            <div className="star2 s-4"></div>
            <div className="star5 s-5"></div>
            <div className="star2 s-6"></div>
            <div className="star3 s-7"></div>
            <div className="star3 s-8"></div>
            <div className="star4 s-9"></div>
            <div className="star4 s-10"></div>
        </React.Fragment>

    );
}

export default Stars;