import React from 'react';
import NotFoundSvg from '../img/notFound.svg';

function NotFound() {
    return (
        <div className='masthead'>
            <div className='not-found'>
                <object type="image/svg+xml" data={NotFoundSvg} img width="100%">svg-animation</object>
            </div>
        </div>
    );
}

export default NotFound;