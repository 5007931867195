import React from 'react';
import '../styles/projects.scss'

const ThumbnailsContainer = ({ children, id, classList }) => {
    return (
        <div className="description-section opaque" id={id}>
            <div  className={`thumbnail-container description-wrapper ${classList}`}>
                {children}
            </div>
        </div>
    );
}

function ThumbnailColumns({ alt, description, image, subtitle, title }) {
    return (
        <div className="thumbnail">
            <div className="row">
                <div className ="column1">
                    <div className="image-section">
                        <div style={{ backgroundImage: `url(${image})` }} alt={alt} className="thumbnail-image"/>
                    </div>
                </div>
                <div className ="column2">
                    <h4 className="column-title">{title}</h4>
                    <h5 className="column-subtitle">{subtitle}</h5>
                    <div className="column-description">{description}</div>
                </div>
            </div>
        </div>
    );
}

function ThumbnailRow({ alt, description, id, image, title }) {
    return (
        <div className="thumbnail">
            <div className="description-section" id={id}>
                <div className="container description-wrapper">
                    <h2 className="section-header">{title}</h2>
                    <p className="section-desc">{description}</p>
                </div>
            </div>
            <div className="image-section">
                <div className="image-container flex">
                    <img src={image} alt={alt} className="project-image"/>
                </div>
            </div>
        </div>
    );
}

function ThumbnailConclusion({ githubLink, summaryText, websiteUrl }) {
    return (
        <div className="thumbnail">
            <div className="description-section " >
                <div className="container description-wrapper">
                    <h2 className="section-header">Lessons learned and highlights</h2>
                    <div className="lessons-learned">
                        <p id="lesson">{summaryText}</p>
                        <div className="project-links">
                            {githubLink &&
                                <a href={githubLink} target="_blank" rel="noopener noreferrer">View code</a>
                            }
                            {websiteUrl &&
                                <React.Fragment>
                                    <div id="link-divider"></div>
                                    <a href={websiteUrl} target="_blank" rel="noopener noreferrer">View website</a>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ThumbnailsContainer, ThumbnailRow, ThumbnailColumns, ThumbnailConclusion };
