import React from "react"
import { ThumbnailRow, ThumbnailColumns, ThumbnailConclusion, ThumbnailsContainer } from './Thumbnail.js';
import ProjectSection from './ProjectSection';
import Contact from './Contact';

function AustinsListProject() {
    return (
        <div className="main-content">
            <ProjectSection
                type="Ecommerce Web Application"
                title="Austin's List"
                subtitle="Shop local Austin designers and artist's work!"
                goal="Build an Ecommerce Web Application which provides a platform for small business owners to manage their portfolio of work and create revenue through a rich catalog focused on sleek design and user experience."
                solution="I focused on creating a performant site, using Next.js with server side rendering, with fresh and clean visuals and an emphasis on UX that invite's the user to keep coming back. I was thoughtful to create simple and intuitive navigation, and lots of product visuals."
                list={["Next.js", "React", "Apollo Client", "Styled Components", "Node.js", "Express", "GraphQL", "Prisma", "Jest", "React-Testing-Library"]}
            />
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    id="pt-12"
                    title="Clean website to emphasize visuals"
                    description="Showcasing the items for potential consumers was a priority. Therefore, throughout the site the item images are front and center."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1599457653/AustinArts%20/Screen_Shot_2020-09-07_at_12.47.18_AM_odgdlw.png"
                    alt="Homepage"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="mbl-p0">
                <ThumbnailColumns
                    title = "Shopping Cart"
                    description ="Built with both Apollo's render prop pattern as well as Apollo's newer Hooks API. Apollo's ability to update the UI to reflect changes in the cache, results in fast updates!
				This feature allows the user to easily verify and update cart items, and then proceed back to shopping for more!"
                    image="https://res.cloudinary.com/dddnhychw/image/upload/q_auto/v1598836227/AustinArts%20/Screen_Shot_2020-08-30_at_8.10.05_PM_uhfmti.png"
                    alt="Shopping Cart"
                />
                <div id="background-sphere-1"></div>

            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Expandable Search Bar"
                    description="Learned more about event pooling in React and effectively used e.persist() to access the event object in an asynchronous way. Used Refs to manage adding focus and clearing the selection from the search bar. Utilized Lodash's debounce to improve efficiency and only request data from the DB every 350ms versus every key stroke."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1599457833/AustinArts%20/Screen_Shot_2020-09-07_at_12.50.21_AM_cfib7m.png"
                    alt="Search Bar"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="mbl-p0">
                <ThumbnailColumns
                    title="Responsive"
                    description="Built with mobile and tablet sizes in mind."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1599262029/AustinArts%20/Screen_Shot_2020-09-04_at_6.26.05_PM_kmusqr.png"
                    alt="mobile friendly view"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="pt-0">
                <ThumbnailRow
                    title="Drag and Drop, and upload multiple photos"
                    description="Built in error handling and form validation to validate correct file types on drag and drop as well as handling decimal point variations when editing the price. To direct the user to the correct page after saving their product, I created a higher order component to pass the total count of items in the store down to the “CreateItem” component as “props” which is used to do a refetch query of the paginated items and direct the user to the correct page."
                    image="https://res.cloudinary.com/dddnhychw/image/upload/v1600206842/AustinArts%20/Screen_Shot_2020-09-15_at_4.53.42_PM_bslg2m.png"
                    alt="Edit existing item view"
                />
            </ThumbnailsContainer>
            <ThumbnailsContainer id="mbl-p0">
                <ThumbnailConclusion
                    summaryText="With this project, some experiences that stood out to me were working with JWT authentication, pagination, a search bar,
                    and working with React-Testing-Library. I particularly enjoyed taking what I learned with this project with a GraphQL API and applying
                    it to the code base at work which is a REST API with a SQL DB. I gained a greater appreciation for how you can apply features such as authentication and pagination, with both
                    styles of API's, and how to adapt as needed due to the tech stack."
                    githubLink="https://github.com/Sergio16T/Austins-List-Ecommerce"
                />
            </ThumbnailsContainer>
            <Contact/>
        </div>
    )
}


export default AustinsListProject;

// description ="Full Stack Application built with React, Next.js, Node.js, Express, GraphQL, Apollo Client, GraphQL Yoga, Prisma, and Styled Components. Worked with downshift(search bar), pagination, persisting state between pages(shopping cart), purchase with stripe, authentication, JWT, and Cookies. Built with responsive design."