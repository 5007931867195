import React from 'react';
import Masthead from './Masthead';
import About from './About';
import Contact from './Contact';
import ActionButton from './ActionButton';

const HomePage = ({ contentRef, scrollIntoView }) => {
    return (
        <div>
            <Masthead
                render={() => <ActionButton scrollIntoView={scrollIntoView} text="About Me" to= "/"/>}
                subtitle="Software Engineer"
                title="Sergio Tapia-Fikes"
            />
            <About contentRef={contentRef}/>
            <Contact/>
        </div>
    );
}

export default HomePage;